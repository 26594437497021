import Home from '../components/Home/Home';

const HomePage = () => {
  // const [loading, setLoading] = useState(true);

  // useLayoutEffect(() => {
  //   const onPageLoad = (e) => {
  //     console.log(e, 'He');
  //     setLoading(false);
  //   };

  //   if (document.readyState === 'complete') {
  //     setLoading(false);
  //   } else {
  //     window.addEventListener('load', onPageLoad);
  //     return window.removeEventListener('load', onPageLoad);
  //   }
  // // }, []);

  // window.addEventListener('load', () => setLoading(false));

  // if (loading)
  //   return (
  //     <div className="loader">
  //       <div className="lds-facebook">
  //         <div></div>
  //         <div></div>
  //         <div></div>
  //       </div>
  //     </div>
  //   );
  return (
    <>
      <Home />
    </>
  );
};

export default HomePage;
