import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Layout/Header';
const Join = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const navigate = useNavigate();
  const addUser = async (user) => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        'https://api.silfrica.com/api/mailing-list',
        user
      );
      if (!data.success) {
        setError(true);
        setErrMsg(data.data.email[0]);
      } else {
        navigate('/welcome');
        setError(false);
      }
    } catch (error) {
      setError(true);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !email) {
      setError(true);
      setErrMsg('All fields are required');
      return;
    }
    await addUser({ name: name.trim(), email: email.trim() });
  };
  return (
    <>
      <section className="main-join">
        <Header />
        <div className="join px-[24px] max-w-[384px] mx-auto grid pt-[15vh]">
          <div>
            <div>
              <h3 className="text-[#0F2936] text-[36px] font-bold mb-[4px] tracking-wide">
                Join the waitlist
              </h3>
              <p className="text-[#163C49] text-[16px]  leading-[24px] text-r mb-[48px]">
                Join Silfrica Community
              </p>
            </div>
            <div>
              <form className="flex flex-col w-[100%]" onSubmit={handleSubmit}>
                <div className="flex flex-col space-y-2 mb-[16px]">
                  <label htmlFor="" className="text-[#2E505C] font-medium">
                    Fullname
                  </label>
                  <input
                    type="text"
                    placeholder="John Doe"
                    className="border border-[#8A9DA3] rounded-lg h-[44px] hover:outline-none focus:outline-none p-2 w-[100%]"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="flex flex-col space-y-2 mb-[8px]">
                  <label htmlFor="" className="text-[#2E505C] font-medium">
                    Email
                  </label>
                  <input
                    type="email"
                    placeholder="john@gmail.com"
                    className="border border-[#8A9DA3] rounded-lg h-[44px] hover:outline-none focus:outline-none p-2 w-[100%]"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {error && <p className="error_text">{errMsg}</p>}
                </div>
                <div className='flex gap-4 items-center mb-[28px] p-2'>
                    <input type="checkbox" name="marketing" className='outline-none accent-[#00A88F]' required/>
                  <p>I agree to recieve Marketing emails and information about special deals.</p>
                </div>
                <button
                  type="submit"
                  disabled={loading}
                  className="btn-green text-white w-[100%]"
                >
                  {loading ? 'Loading' : 'Submit'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Join;
