import React, { lazy, Suspense } from 'react';
import image1 from '../../img/hero-business.svg';
import student from '../../img/student-talent.svg';
import trend from '../../img/data.svg';
import campuses from '../../img/campuses.svg';
import campaigns from '../../img/campaigns.svg';
import OpportunityCard from '../Home/Cards/OpportunityCard';
import frame from '../../Assets/header.svg';
import { Link } from 'react-router-dom';

const Image = lazy(() => import('../ImageComp'));

const Hero = () => {
  return (
    <div className="main lg:pt-[101px]">
      <div className="lg:max-w-[80%] mx-auto px-[24px] lg:px-0">
        <div className="flex flex-col lg:flex-row lg:justify-between lg:items-start lg:space-x-[105px] mb-[100px] lg:mb-[238px]">
          <div className="flex-1">
            <header className="relative">
              <img
                src={frame}
                alt=""
                className="absolute w-[100%] lg:w-[543px] -bottom-36 lg:left-12 -z-10 opacity-60"
              />

              <h2 className="lg:hidden text-[36px] lg:text-[2.8rem] xl:text-[3rem] font-bold mb-6">
                Sell to campuses from anywhere anytime
              </h2>
              <h2 className="hidden lg:block text-[36px] lg:text-[2.8rem] xl:text-[3rem] font-bold mb-6 whitespace-nowrap">
                Sell to campuses <br /> from anywhere <br /> anytime
              </h2>
              <p className="lg:hidden text-[#163C49] text-[20px] leading-[30px] mb-[52px]">
                Connect with top student creators from all campuses with our
                one-point access platform. Increase brand awareness,
                collaborate, organize events, or tap into fresh ideas.
              </p>
              <p className="hidden lg:block text-[#163C49] text-[16px] xl:text-[20px] leading-[30px] mb-[52px] whitespace-nowrap">
                Connect with top student creators from all campuses with <br />{' '}
                our one-point access platform. Increase brand awareness, <br />
                collaborate, organize events, or tap into fresh ideas.
              </p>
            </header>
            <div className="flex flex-col lg:flex-row space-x-[24px]">
              <Link
                to="/join"
                className="btn-green new-btn text-white shadow-lg hover:shadow-xl"
              >
                Join the waitlist
              </Link>
            </div>
          </div>
          <Suspense
            fallback={
              <div className="mt-[106.8px] w-[100%] lg:w-[40%] lg:mt-0 bg-gray-400"></div>
            }
          >
            <div className="mt-[106.8px] lg:w-[40%] lg:mt-0">
              <Image
                src={image1}
                alt="Working people"
                classes="w-[100%] h-[100%]"
              />
            </div>
          </Suspense>
        </div>
        <div className="grid place-items-center pb-[40px]">
          <h3 className="lg:hidden mb-[64px] text-[26px] leading-[48.6px] font-bold place-self-start">
            Unlock the power of data <br /> analytics to build and scale <br />{' '}
            your brand across campuses
          </h3>
          <h3 className="hidden lg:block mb-[64px] text-[26px] lg:text-[32px] leading-[48.6px] font-bold lg:text-center">
            Unlock the power of data analytics to build <br /> and scale your
            brand across campuses
          </h3>
          <div className="grid lg:grid-cols-2 gap-[24px]">
            <OpportunityCard
              avatar={student}
              content="Collaborate with student creators to drive growth with our simplified system."
            />
            <OpportunityCard
              avatar={trend}
              content="Get access to data analytics and trends on campuses"
            />
            <OpportunityCard
              avatar={campaigns}
              content="Manage events and campaigns on campuses"
            />
            <OpportunityCard
              avatar={campuses}
              content="Build & manage communities across campuses"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
