import React from 'react';
import Hero from './Hero';
import jones from '../../Assets/jones.png';
import jones1 from '../../Assets/jones2.png';
import jones2 from '../../Assets/jones3.png';
import grid from '../../Assets/grid.png';

import InfoCard from './InfoCard';
import { Link } from 'react-router-dom';

const Business = () => {
  return (
    <section className="mb-[64px]">
      <Hero />
      <section className="bg-white px-[24px] py-[88px] lg:py-[100px]">
        <div className="lg:w-[80%] mx-auto">
          <div>
            <h2 className="md:hidden mb-[60px] text-[26px] leading-[48.6px] font-bold md:text-center">
              Discover the Impact of <br /> Connecting with Student <br />{' '}
              Creators:
            </h2>
            <h3 className="hidden md:block mb-[60px]  text-[26px] lg:text-[32px] leading-[48.6px] font-bold md:text-center">
              Discover the Impact of Connecting with <br /> Student Creators:
            </h3>
          </div>
          <div className="grid-card xl:max-w-[80%] mx-auto">
            <InfoCard
              image={jones}
              name="John jones"
              role="Buildfoundation"
              desc="Silfrica helped us to tap into a diverse pool of student talent and increase brand awareness on campus. "
            />
            <InfoCard
              image={jones1}
              name="John jones"
              role="Buildfoundation"
              desc="Collaborating with student creators through this platform brought fresh ideas and new perspectives."
            />
            <InfoCard
              image={jones2}
              name="John jones"
              role="Buildfoundation"
              desc="Silfrica helped us to tap into a diverse pool of student talent and increase brand awareness on campus. "
            />
          </div>
        </div>
      </section>
      <div className="flex justify-center relative lg:mt-[40px] px-[24px] h-auto">
        <img src={grid} alt="frame" className="h-[365px] md:h-auto" />
        <div className="absolute flex items-center justify-center ">
          <div className="w-full flex flex-col items-center justify-center">
            <p className="hidden lg:block text-[23px] lg:text-[36px] font-[590] pt-[63px] text-center">
              See all the amazing ways to grow your
            </p>
            <p className="lg:hidden text-[23px] lg:text-[36px] font-[590] pt-[63px] text-center">
              See all the amazing <br /> ways to grow your
            </p>
            <h3 className="lg:hidden text-[36px] sm:text-[64px] font-[700] lg:font-[590]  text-center gradient-text">
              Business in <br /> campuses
            </h3>
            <h3 className="hidden lg:block text-[36px] sm:text-[64px] font-[700] lg:font-[590]  text-center gradient-text">
              Business in campuses
            </h3>
            <Link
              to="/join"
              className="cta-btn mt-[40px] text-white text-[16px] shadow-lg hover:shadow-xl"
            >
              Join the waitlist
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Business;
