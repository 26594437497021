import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import silfrica from '../../img/main-logo.png';
import Sidebar from './Sidebar';
import { FaBars, FaTimes } from 'react-icons/fa';
import Announcement from './Annoucement';

const Header = () => {
  const [sticky, setSticky] = useState(false);
  const [navBar, setNavbar] = useState(false);
  const ref = useRef(null);
  const nav = useRef(null);

  useEffect(() => {
    const getHeight = window.addEventListener('scroll', () => {
      const windowHeight = window.scrollY;
      const height = ref.current?.getBoundingClientRect().height;
      if (windowHeight > height) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    });

    return window.removeEventListener('scroll', getHeight);
  }, [sticky]);

 
  const handleClick = () => {
    setNavbar((prev) => !prev);
  };
  const closeNavbar = () => {
    handleNavClick();
  };

  const handleNavClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setNavbar(false);
  };

  return (
    <header
      ref={ref}
      className={`lg:h-[130px] ${sticky && 'fixed'} pb-[24.35px]`}
    >
      <Announcement />
      <nav className="lg:max-w-[80%] lg:mx-auto lg:flex justify-between items-center relative lg:px-[24px] pt-2">
        <div className="flex justify-between px-[24px] lg:px-0">
          <div>
            <Link to="/" onClick={handleNavClick}>
              <img src={silfrica} alt="Silfrica" className="w-[160px]" />
            </Link>
          </div>
          <button
            className="lg:hidden"
            aria-label="toggle button"
            onClick={handleClick}
          >
            {navBar ? (
              <FaTimes size={25} className="hover:text-[#00A88F]" />
            ) : (
              <FaBars size={25} className="hover:text-[#00A88F]" />
            )}
          </button>
        </div>
        <div className="hidden lg:block lg:px-[84px]">
          <ul className="flex space-x-[10px]">
            <li>
              <NavLink
                to="/business"
                onClick={handleNavClick}
                className={({ isActive }) =>
                  isActive
                    ? 'text-[18px] px-4 pb-2 border-b-2 border-[#00A88F] text-[#00A88F]'
                    : 'text-[18px] px-4 hover:text-[#00A88F]'
                }
              >
                Business
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/creators"
                onClick={handleNavClick}
                className={({ isActive }) =>
                  isActive
                    ? 'text-[18px] px-4  pb-2 border-b-2 border-[#00A88F] text-[#00A88F]'
                    : 'text-[18px] px-4 hover:text-[#00A88F]'
                }
              >
                Creators
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/campus"
                onClick={handleNavClick}
                className={({ isActive }) =>
                  isActive
                    ? 'text-[18px] px-4 pb-2 border-b-2 border-[#00A88F] text-[#00A88F]'
                    : 'text-[18px] px-4  hover:text-[#00A88F]'
                }
              >
                Campus
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="hidden lg:flex space-x-5">
          <Link
            to="/join"
            className="btn-green text-white text-[14px] whitespace-nowrap shadow-lg hover:shadow-xl"
          >
            Join the waitlist
          </Link>
        </div>
      </nav>
      <div ref={nav} className="lg:hidden bg-white px-[24px]">
        {navBar && <Sidebar closeSidebar={closeNavbar} />}
      </div>
    </header>
  );
};

export default Header;
