import Campus from '../components/Campus/Campus';

const CampusPage = () => {
  return (
    <>
      <Campus />
    </>
  );
};

export default CampusPage;
