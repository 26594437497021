const OpportunityCard = ({ avatar, content }) => {
  return (
    <article className="box px-[24px] py-[32px] shadow-sm pro-text">
      <img src={avatar} alt="Connect" className="mb-[24px]" />
      <p className="text-[20px] leading-[30px] font-[500]">{content}</p>
    </article>
  );
};

export default OpportunityCard;
