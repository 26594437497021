import silfrica from '../../img/main-logo.png';
import footer from '../../Assets/footer.png';
import { footerLinks } from '../../utils';
import apple from '../../Assets/icons/apple.png';
import and from '../../Assets/icons/and.png';
import twit from '../../Assets/icons/twit.png';
import linked from '../../Assets/icons/linked.png';
import tic from '../../Assets/icons/tic.png';
import insta from '../../Assets/icons/insta.png';

const Footer = () => {
  return (
    <footer>
      <div className="px-[24px] lg:max-w-[80%] lg:mx-auto lg:mt-[176px]">
        <div className="flex lg:gap-x-[80px] xl:gap-x-[100px] flex-col lg:flex-row">
          <div className="mb-[70px] lg:mb-0">
            <div className="mb-[39px]">
              <img src={silfrica} alt="Silfrica" className="w-[160px]" />
            </div>

            <div className="flex space-x-2 mb-[52px]">
              <span>
                <img src={apple} alt="" className="w-[87.96px] h-[29.67px]" />
              </span>
              <span>
                <img src={and} alt="" className="w-[87.96px] h-[29.67px]" />
              </span>
            </div>
            <div className="flex space-x-2">
              <a
                href="https://www.instagram.com/silfrica/"
                className="hover:animate-ping"
              >
                <img src={insta} alt="https://www.instagram.com/silfrica/" />
              </a>
              <a
                href="https://twitter.com/Silfrica"
                className="hover:animate-ping"
              >
                <img src={twit} alt="https://twitter.com/Silfrica" />
              </a>
              <a
                href="https://www.linkedin.com/company/silfrica-technologies-limited/"
                className="hover:animate-ping"
              >
                <img
                  src={linked}
                  alt="https://www.linkedin.com/company/silfrica-technologies-limited/"
                />
              </a>
              <a
                href="https://vm.tiktok.com/ZMY5CMo3p/"
                className="hover:animate-ping"
              >
                <img src={tic} alt="https://vm.tiktok.com/ZMY5CMo3p/" />
              </a>
            </div>
          </div>
          <div className="flex-1 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 mb-[88px]">
            {footerLinks.map((link, index) => (
              <article key={index} className="mb-[32px]">
                <h4 className="mb-[21px] text-[16px] text-black">
                  {link.title}
                </h4>
                <div>
                  {link.content.map((link, index) => {
                    return (
                      <button
                        className="mb-[12px] font-normal text-[0.875rem] text-black"
                        key={index}
                      >
                        {link}
                      </button>
                    );
                  })}
                </div>
              </article>
            ))}
          </div>
        </div>
        <div className="relative h-[185px]">
          <p className="text-center">
            Privacy policy Terms of service &copy;{new Date().getFullYear()}{' '}
            Silfrica. All rights reserved.
          </p>
          <img
            src={footer}
            alt=""
            className="hidden md:block md:absolute bottom-0  -z-10 h-[303.85px]"
          ></img>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
