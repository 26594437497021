import Creators from '../components/Creators/Creators';

const CreatorsPage = () => {
  return (
    <>
      <Creators />
    </>
  );
};

export default CreatorsPage;
