export const footerLinks = [
  {
    title: 'Creator',
    content: ['How it works', 'Blog', 'Brands', 'Testimonials'],
  },
  {
    title: 'Campuses',
    content: ['Marketing', 'Resources', 'Events', 'Security', 'Request demo'],
  },
  {
    title: 'Company',
    content: ['About', 'Join us', 'Press', 'Legal', 'Brand'],
  },
  { title: 'Support', content: ['Creators', 'Business', 'Campuses'] },
  {
    title: 'Businesses',
    content: [
      'Pricing',
      'Products',
      'Customers',
      'Resources',
      'Blog',
      'Request demo',
    ],
  },
];
