import { Link, NavLink } from 'react-router-dom';

const Sidebar = ({ closeSidebar }) => {
  return (
    <div className="flex flex-col justify-between nav-height pb-[50px]">
      <ul className="flex flex-col space-y-[16px] mt-[32px]">
        <li>
          <NavLink
            to="/"
            onClick={closeSidebar}
            className={({ isActive }) =>
              isActive
                ? 'text-[18px] px-4 pb-2 border-b-2 border-[#00A88F] text-[#00A88F]'
                : 'text-[18px] px-4 hover:text-[#00A88F]'
            }
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/business"
            onClick={closeSidebar}
            className={({ isActive }) =>
              isActive
                ? 'text-[18px] px-4 pb-2 border-b-2 border-[#00A88F] text-[#00A88F]'
                : 'text-[18px] px-4 hover:text-[#00A88F]'
            }
          >
            Business
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/creators"
            onClick={closeSidebar}
            className={({ isActive }) =>
              isActive
                ? 'text-[18px] px-4  pb-2 border-b-2 border-[#00A88F] text-[#00A88F]'
                : 'text-[18px] px-4 hover:text-[#00A88F]'
            }
          >
            Creators
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/campus"
            onClick={closeSidebar}
            className={({ isActive }) =>
              isActive
                ? 'text-[18px] px-4 pb-2 border-b-2 border-[#00A88F] text-[#00A88F]'
                : 'text-[18px] px-4  hover:text-[#00A88F]'
            }
          >
            Campus
          </NavLink>
        </li>
      </ul>
      <div className="flex flex-col gap-6">
        <Link to="/join" className="btn-green text-white">
          Join the waitlist
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
