const InfoCard = ({ image, name, role, desc }) => {
  return (
    <article className="article md:w-[330.49px]">
      <img src={image} alt={name} className="mb-[28px]" />
      <h4 className="text-[18px] font-[590] text-[#0E1428] mb-1">{name}</h4>
      <p className="text-[#163C49] text-[12px] font-[500] opacity-60 mb-[12px]">
        {role}
      </p>
      <p className="text-[#163C49] text-[16px] leading-[24px]">{desc}</p>
    </article>
  );
};

export default InfoCard;
