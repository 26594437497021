import OpportunityCard from './Cards/OpportunityCard';
import image1 from '../../img/main.svg';
import Access from '../../img/Access.svg';
import Communities from '../../img/communities.svg';
import Data from '../../img/data.svg';
import Connect from '../../img/Connect.svg';

import frame from '../../Assets/header.svg';
import { Link } from 'react-router-dom';
import { Suspense, lazy } from 'react';
const Image = lazy(() => import('../ImageComp'));

const Hero = () => {
  return (
    <div className="main lg:pt-[101px]">
      <div className="lg:max-w-[80%] px-[24px] lg:px-0 mx-auto">
        <div className="flex flex-col lg:flex-row lg:justify-between md:items-start lg:space-x-[105px] mb-[100px] lg:mb-[138px]">
          <div className="flex-1 mb-[66.8px]">
            <header className="relative">
              <img
                src={frame}
                alt=""
                className="absolute w-[100%] lg:w-[543px] -bottom-36 lg:left-12 -z-10 opacity-60"
              />
              <h2 className="text-[36px] lg:text-[2.8rem] xl:text-[3rem] font-bold mb-6 whitespace-nowrap">
                Create & Access <br /> Opportunities in <br /> your Campus
              </h2>
              <p className="hidden lg:block text-[#163C49] text-[16px] xl:text-[20px] leading-[30px] mb-[52px] whitespace-nowrap">
                Follow trending content from student creators and
                <br />
                businesses across campuses. Access all the verified <br />{' '}
                information channels across campuses.
              </p>
              <p className="lg:hidden text-[#163C49] text-[16px] font-normal leading-[30px] mb-[52px] whitespace-nowrap">
                Follow trending content from student <br /> creators and
                businesses across campuses. <br /> Access all the verified
                information channels <br />
                across campuses.
              </p>
            </header>
            <div className="flex flex-col lg:flex-row space-x-[24px]">
              <Link
                to="/join"
                className="btn-green new-btn text-white shadow-lg hover:shadow-xl"
              >
                Join the waitlist
              </Link>
            </div>
          </div>
          <Suspense
            fallback={
              <div className="mt-[106.8px] w-[100%] lg:w-[40%] lg:mt-0 bg-gray-400"></div>
            }
          >
            <div className="mt-[106.8px] lg:w-[40%] lg:mt-0">
              <Image
                src={image1}
                alt="Working people"
                classes="h-[100%] w-[100%]"
              />
            </div>
          </Suspense>
        </div>
      </div>
      <div className="grid place-items-center pb-[40px]">
        <h3 className="md:hidden mb-[64px] px-[24px] text-[26px] leading-[48.6px] font-bold">
          Discover opportunities and stay informed on campuses with Silfrica
        </h3>
        <h3 className="hidden md:block mb-[64px] px-[24px] text-[26px] lg:text-[32px] leading-[48.6px] font-bold md:text-center">
          Discover opportunities and stay informed <br /> on campuses with
          Silfrica
        </h3>
        <div className="grid md:grid-cols-2 gap-[24px] px-[24px] lg:px-0">
          <OpportunityCard
            avatar={Connect}
            content="Drive brand awareness and get paid by collaborating with businesses as a creator"
          />
          <OpportunityCard
            avatar={Access}
            content="Know the latest trends on campuses through our analytics"
          />
          <OpportunityCard
            avatar={Communities}
            content="Build and join communities to drive social causes"
          />
          <OpportunityCard
            avatar={Data}
            content="Never miss any event on campuses with Silfrica"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
