import { Link } from 'react-router-dom';
import grid from '../../../Assets/grid.png';

const Cta = ({ title, sub, btn }) => {
  return (
    <div className="relative cta">
      <img src={grid} alt="Grid lines" className="absolute w-[100%] h-[100%]" />
      <div className="top-[63px] absolute w-[100%] z-10">
        <div className="grid place-items-center">
          <p className="text-[#0F2936] text-[23px]  lg:text-[36px] font-[590] mb-1 lg:mb-0">
            {sub}
          </p>
          <div className="lg:w-[613.55px] h-[83px] mb-[28px] lg:mb-[44px] text-center">
            <h3 className="text-[36px] lg:text-[64px] font-[700] lg:font-[590] gradient-text whitespace-nowrap z-10">
              {title}
            </h3>
          </div>
          <Link
            to="/join"
            className="cta-btn text-white shadow-lg hover:shadow-xl"
          >
            {btn}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Cta;
