import React, { lazy, Suspense } from 'react';
import hero from '../../img/hero-campus.svg';
import jones from '../../Assets/jones.png';
import jones1 from '../../Assets/jones2.png';
import jones2 from '../../Assets/jones3.png';
import frame from '../../Assets/header.svg';
import Monitor from '../../Assets/monitor.svg';
import Valuable from '../../Assets/Valuable-data.svg';
import information from '../../Assets/information.svg';
import creativity from '../../Assets/creativity.svg';
import Group from '../../Assets/Group 1.svg';
import Vector from '../../Assets/Vector.svg';
import InfoCard from '../Business/InfoCard';
import OpportunityCard from '../Home/Cards/OpportunityCard';
import grid from '../../Assets/grid.png';
const Image = lazy(() => import('../ImageComp'));

const Campus = () => {
  return (
    <div className="mb-[64px]">
      <div className="main lg:pt-[101px]">
        <div className="lg:max-w-[80%] mx-auto px-[24px] lg:px-0">
          <div className="flex flex-col lg:flex-row lg:justify-between lg:items-start lg:px[8px] lg:space-x-[105px] mb-[100px] lg:mb-[238px]">
            <div className="flex-1">
              <header className="relative">
                <img
                  src={frame}
                  alt=""
                  className="absolute w-[100%] lg:w-[543px] -bottom-36 lg:left-12 -z-10 opacity-60"
                />
                <h2 className="text-[36px] lg:hidden lg:text-[2.8rem] xl:text-[3rem] font-bold mb-6">
                  Create a seamless communication experience for your
                  institution
                </h2>
                <h2 className="hidden lg:block text-[36px] lg:text-[2.8rem] xl:text-[3rem] font-bold mb-6 whitespace-nowrap">
                  Create a seamless <br /> communication <br /> experience for
                  your <br />
                  institution
                </h2>
                <p className="lg:hidden text-[#163C49] text-[20px] leading-[30px] mb-[52px]">
                  Our platform simplifies the complex process of managing
                  multiple information sources and staff involved in information
                  dissemination, enabling efficient communication to reach its
                  intended audience.
                </p>
                <p className="hidden lg:block text-[#163C49] text-[16px] xl:text-[20px] leading-[30px] mb-[52px] whitespace-nowrap">
                  Our platform simplifies the complex process of managing <br />
                  multiple information sources and staff involved in information{' '}
                  <br />
                  dissemination, enabling efficient communication to reach its{' '}
                  <br />
                  intended audience.
                </p>
              </header>
              <div className="flex flex-col lg:flex-row space-x-[24px]">
                <a
                  href="mailto:sales@silfrica.com"
                  className="btn-green new-btn text-white"
                >
                  Contact us
                </a>
              </div>
            </div>
            <Suspense
              fallback={
                <div className="mt-[106.8px] w-[100%] lg:w-[40%] lg:mt-0 bg-gray-400"></div>
              }
            >
              <div className="mt-[106.8px] lg:w-[40%] lg:mt-0">
                <Image src={hero} alt="Working people" classes="w-[100%]" />
              </div>
            </Suspense>
          </div>
          <div className="grid place-items-center pb-[40px]">
            <h3 className="lg:hidden mb-[64px] text-[28px] leading-[48.6px] font-bold lg:text-center place-self-start">
              Unlock the Benefits of Collaborating with Silfrica for Your
              Institution
            </h3>
            <h3 className="hidden lg:block mb-[64px] text-[36px] leading-[48.6px] font-bold lg:text-center">
              Unlock the Benefits of Collaborating with <br /> Silfrica for Your
              Institution
            </h3>
            <div className="grid md:grid-cols-2 gap-[24px]">
              <OpportunityCard
                avatar={information}
                content="Manage institutions multiple information architecture on one platform."
              />
              <OpportunityCard
                avatar={Valuable}
                content="Access valuable data that can help the institution to make informed decisions."
              />
              <OpportunityCard
                avatar={Monitor}
                content="Implement and monitor compliance with institutional policies, with convenient feedback channels."
              />
              <OpportunityCard
                avatar={creativity}
                content="Showcase the institution's talents and creativity to a wider audience."
              />
            </div>
          </div>
        </div>
      </div>

      {/* contact us */}

      <section className="lg:max-w-[80%] mx-auto px-[24px] lg:px-0 mb-[100px] sm:mb-[40px] lg:mb-0">
        <div className="lg:[638px] xl:h-[834px] h-[425px] grid place-items-center relative">
          <img
            src={Group}
            alt="svg"
            className="absolute w-[250px] lg:w-[343px] xl:w-[543px] right-1 -top-2 lg:right-12 -z-10 opacity-60"
          />

          <div className="flex flex-col items-center">
            <h1 className="hidden lg:block max-w-[682px] text-[36px] font-bold text-center mb-[32px]">
              Secure and Reliable Information <br /> Management with Silfrica
            </h1>
            <h2 className="lg:hidden text-[28px] font-bold text-center mb-[32px]">
              Secure and Reliable Information Management with Silfrica
            </h2>
            <p className="text-[#163C49] max-w-[682px] text-center text-[23px] mb-[20px] lg:mb-0">
              Our information management system is designed with top-notch
              security features that ensure that information is only accessible
              to authorized personnel.
            </p>
            <button className="btn-green text-white mt-[20px] shadow-lg hover:shadow-xl">
              Learn more
            </button>
          </div>
          <img
            src={Vector}
            alt="svg"
            className="absolute left-4 lg:left-30 xl:left-40 bottom-12 lg:bottom-0 xl:bottom-10 w-[200px] lg:w-auto"
          />
        </div>
      </section>
      {/* CREATORS */}
      <section className="bg-white px-[24px]">
        <div className="lg:max-w-[80%] xl:max-w-[1055px] mx-auto">
          <div>
            <h3 className="lg:hidden mb-[32px] lg:mb-[124px] text-[36px] leading-[48.6px] font-bold lg:text-center">
              Successful information management: Testimonials from our partner
              institutions
            </h3>
            <h3 className="hidden lg:block mb-[32px] lg:mb-[124px] text-[36px] leading-[48.6px] font-bold lg:text-center">
              Successful information management: <br /> Testimonials from our
              partner institutions
            </h3>
          </div>
          <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-[48px]">
            <InfoCard
              image={jones}
              name="John jones"
              role="Buildfoundation"
              desc="Silfrica helped us to tap into a diverse pool of student talent and increase brand awareness on campus. "
            />
            <InfoCard
              image={jones1}
              name="John jones"
              role="Buildfoundation"
              desc="Collaborating with student creators through this platform brought fresh ideas and new perspectives."
            />
            <InfoCard
              image={jones2}
              name="John jones"
              role="Buildfoundation"
              desc="Silfrica helped us to tap into a diverse pool of student talent and increase brand awareness on campus. "
            />
          </div>
        </div>
      </section>
      <section className="relative mt-[88px] lg:max-w-[80%] mx-auto">
        <div className="flex justify-center relative px-[24px] h-auto lg:h-[350px]">
          <img src={grid} alt="frame" className="h-[365px] lg:h-auto" />
          <div className="absolute flex items-center justify-center ">
            <div className="w-full flex flex-col items-center justify-center">
              <p className="hidden lg:block text-[23px] sm:text-[36px] font-medium pt-[63px] text-center">
                Take your institution to the next
              </p>
              <p className="lg:hidden text-[23px] sm:text-[25px] font-medium pt-[63px] text-center">
                Take your <br /> institution to the next
              </p>
              <h3 className="lg:hidden text-[36px] font-[700] lg:font-[590] text-center gradient-text">
                level of digital <br /> innovation
              </h3>
              <h3 className="hidden lg:block text-[36px] sm:text-[64px] font-[700] lg:font-[590] text-center gradient-text">
                level of digital innovation
              </h3>
              <a
                href="mailto:sales@silfrica.com"
                className="cta-btn text-white mt-[40px] text-[16px] shadow-lg hover:shadow-xl"
              >
                Contact us
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Campus;
