import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  BusinessPage,
  CampusPage,
  CreatorsPage,
  HomePage,
  SharedLayout,
  Join,
  Welcome,
} from './Pages';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SharedLayout />}>
          <Route index element={<HomePage />} />
          <Route path="business" element={<BusinessPage />} />
          <Route path="creators" element={<CreatorsPage />} />
          <Route path="campus" element={<CampusPage />} />
        </Route>
        <Route path="join" element={<Join />} />
        <Route path="welcome" element={<Welcome />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
