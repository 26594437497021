import Hero from './Hero';

import Business from '../../Assets/business.png';
import Creators from '../../Assets/creators.png';
import Institutions from '../../Assets/institution.png';

import Insti from '../../Assets/icons/insti.png';
import bag from '../../Assets/icons/bag.png';
import bulb from '../../Assets/icons/bulb.png';

import './Home.css';
import Cta from './Cards/Cta';
import { Link } from 'react-router-dom';

const Home = () => {
  const handleNavClick = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <section className="mb-[64px]">
      <Hero />
      <section className="bg-white lg:h-[700px] lg🧑 py-[128px]">
        <div className="lg:max-w-[80%] px-[24px] mx-auto">
          <div className="flex items-center flex-col lg:flex-row lg:justify-center lg:gap-[106px]">
            <div className="lg:w-[544px] mb-[48px] lg:mb-0">
              <div className="w-[328px] lg:w-[100%] h-[200px] lg:h-[444px] relative">
                <img
                  src={Business}
                  alt="Silfrica for businesses"
                  className="w-[100%] h-[100%]"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="lg:w-[544px]">
              <img src={bag} alt="" className="mb-[27.6px]" />
              <h3 className="text-[#0F2936] text-[36px] font-bold mb-[12px]">
                Silfrica for business
              </h3>
              <p className="text-[#163C49] text-[16px] lg:text-[18px] leading-[24px] pro-text">
                Sell to campuses from anywhere and at anytime by collaborating
                with creators on campuses.
              </p>
              <Link
                onClick={handleNavClick}
                to="/business"
                className="btn-fill mt-[32px]"
              >
                Learn more
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="view lg:h-[700px] px-[24px] py-8 lg:py-[128px] mb-[40px]">
        <div className="lg:max-w-[80%] mx-auto">
          <div className="flex flex-col lg:flex-row items-center lg:justify-center lg:gap-[106px]">
            <div className="lg:w-[544px] order-2 lg:order-1">
              <img src={bulb} alt="" className="mb-[27.6px]" />
              <h3 className="text-[#0F2936] text-[36px] font-bold mb-[12px]">
                Silfrica for creators
              </h3>
              <p className="text-[#163C49] text-[16px] lg:text-[18px] leading-[24px] pro-text">
                Build your career by collaborating with brands across
                industries. While earning to support your education.
              </p>
              <Link
                onClick={handleNavClick}
                to="/creators"
                className="btn-fill mt-[32px]"
              >
                Learn more
              </Link>
            </div>
            <div className="lg:w-[544px] order-1 lg:order-2 mb-[48px] lg:mb-0">
              <div className="w-[328px] lg:w-[100%] h-[200px] lg:h-[444px] relative">
                <img
                  src={Creators}
                  alt="Silfrica for creators"
                  className="w-[100%] h-[100%]"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white lg:h-[700px] lg:py-[128px]">
        <div className="lg:max-w-[80%] px-[24px] mx-auto">
          <div className="flex flex-col lg:flex-row items-center lg:justify-center lg:gap-[106px]">
            <div className="lg:w-[544px] mb-[48px] lg:mb-0">
              <div className="w-[328px] lg:w-[100%] h-[200px] lg:h-[444px] relative">
                <img
                  src={Institutions}
                  alt="Silfrica for institutions"
                  className="w-[100%] h-[100%]"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="lg:w-[544px]">
              <img src={Insti} alt="" />
              <h3 className="text-[#0F2936] text-[36px] font-bold mb-[12px]">
                Silfrica for institutions
              </h3>
              <p className="text-[#163C49] text-[16px] lg:text-[18px] leading-[24px] pro-text">
                Our platform helps institution create a seamless communication
                channel with all stakeholders.
              </p>
              <Link
                onClick={handleNavClick}
                to="/campus"
                className="btn-fill mt-[32px] shadow-lg hover:shadow-xl"
              >
                learn more
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-[78.5px] lg:mt-0 px-[24px] lg:max-w-[80%] mx-auto">
        <Cta sub="Stay ahead" title="with Silfrica" btn="Join the waitlist" />
      </section>
    </section>
  );
};

export default Home;
