import { Outlet } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";

const SharedLayout = () => {
  return (
    <section>
      <div className="relative">
        <Header />
      </div>
      <Outlet />
      <Footer />
    </section>
  );
};

export default SharedLayout;
