import Business from '../components/Business/Business';

const BusinessPage = () => {
  return (
    <>
      <Business />
    </>
  );
};

export default BusinessPage;
