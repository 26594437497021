import React from 'react';
import play from '../../Assets/play.png';

const Card = (props) => {
  return (
    <div className="rounded-3xl bg-[#00A88F0D] h-[405px] mb-[32px] lg:mb-0">
      <div className="relative">
        <img className="w-auto" src={props.person} alt="creator" />
        <img
          className="absolute top-[40%] left-[40%] "
          src={play}
          alt="play-icon"
        />
      </div>
      <div className="h-[107px] w-auto flex justify-center flex-col font-semibold pl-[24px]">
        <p className="text-[18px] ">{props.name}</p>
        <p className="text-[12px] text-[#163C4999]">{props.position}</p>
      </div>
    </div>
  );
};

export default Card;
