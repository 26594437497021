import { Link } from 'react-router-dom';
import Header from '../components/Layout/Header';
const Welcome = () => {
  return (
    <>
      <section className="main-join">
        <Header />
        <div className="join px-[24px] max-w-[384px] mx-auto flex justify-center items-center text-center">
          <div>
            <div className="mb-[80px]">
              <h3 className="text-[#0F2936] text-[36px] font-bold mb-[4px] tracking-wide">
                Congratulations
              </h3>
              <p className="text-[#163C49] text-[16px]  leading-[24px] text-r">
                You have successfully been added to our waiting list! We will
                get back to you.
              </p>
            </div>
            <div className="btn-welcome">
              <Link to="/">Back</Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Welcome;
