import { useEffect } from 'react';
import { useState } from 'react';
import design from '../../Assets/design.png';
import designn from '../../Assets/designn.png';

const Announcement = () => {
  const slides = [
    {
      text: 'Join our campus ambassadors',
    },
    {
      text: 'Free campaign for your business',
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    const isLastSlide = currentSlide === slides.length - 1;
    const newSlide = isLastSlide ? 0 : currentSlide + 1;
    setCurrentSlide(newSlide);
  };

  const autoScroll = true;
  let slideInterval;
  let intervalTime = 3000;

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  useEffect(() => {
    if (autoScroll) {
      const auto = () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        slideInterval = setInterval(nextSlide, intervalTime);
      };
      auto();
    }
    return () => clearInterval(slideInterval);
  }, [currentSlide, slideInterval, autoScroll]);

  return (
    <div className=" w-full text-center h-[40px] lg:h-[70px]  bg-[#00A88F]   text-white font-bold flex justify-center items-center relative">
      <div className="flex items-center justify-center h-[45px] z-10">
        <p className="text-[14px] md:text-[16px] duration-1000 ease-in-out">
          {slides[currentSlide].text}
        </p>
        <a
          href="https://bit.ly/silfrica-ambassadors"
          rel="noreferrer"
          target="_blank"
        >
          <button className="hover:bg-white hover:ease-in-out hover:duration-500  hover:text-[#51605e] rounded-lg text-white md:text-xl ml-[10px] md:ml-[20px] px-[4px] py-[2px] md:px-[12px] md:py-[2px] border-2 border-white">
            <p className="text-[10px] md:text-[12px]"> Learn more</p>
          </button>
        </a>
      </div>
      <img
        className="absolute left-0 hidden md:flex"
        src={designn}
        alt="icon"
      />
      <img
        className="absolute right-0 hidden md:flex"
        src={design}
        alt="icon"
      />
    </div>
  );
};

export default Announcement;
