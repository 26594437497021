import { Suspense, lazy } from 'react';
import hero from '../../img/header1.svg';
import connections from '../../img/connections.svg';
import insights from '../../img/insights.svg';
import money from '../../img/money.svg';
import talent from '../../img/talent.svg';
import Card from './Card.jsx';
import person1 from '../../Assets/person1.png';
import person2 from '../../Assets/person2.png';
import person3 from '../../Assets/person3.png';
import frame from '../../Assets/header.svg';
import grid from '../../Assets/grid.png';
import { Link } from 'react-router-dom';

const Image = lazy(() => import('../ImageComp'));
const Creators = () => {
  return (
    <div className="w-full h-full">
      {/* HERO SECTION  */}
      <div className="main">
        <div className="px-[24px] lg:px-0 lg:py-0 lg:max-w-[80%] lg:mx-auto">
          <div className="flex flex-col lg:flex-row lg:justify-between md:items-start lg:space-x-[105px] mb-[100px] lg:mb-[138px]">
            <div className="flex flex-col lg:w-[50%] mb-[66.8px] lg:mb-0 lg:mt-[100px]">
              <div className="relative">
                <img
                  src={frame}
                  alt=""
                  className="absolute w-[100%] lg:w-[543px] -bottom-24 lg:-bottom-36 lg:left-12 -z-10 opacity-60"
                />
                <h2 className="lg:hidden text-[#0F2936] text-[36px] font-bold">
                  Work with top brands and earn money as an influencer on campus
                </h2>
                <h2 className="hidden lg:block text-[#0F2936] text-[36px] lg:text-[2.8rem] xl:text-[3rem]  font-bold whitespace-nowrap">
                  Work with top brands <br /> and earn money as <br />
                  an influencer on <br /> campus
                </h2>
                <p className="lg:hidden text-[20px] pt-[16px] pb-[52px] text-[#163C49]">
                  Get the opportunity to enhance your <br /> portfolio and bring
                  your creative visions to life.
                </p>
                <p className="hidden lg:block max-w-[526px] text-[16px] xl:text-[20px] pt-[16px] pb-[52px] text-[#163C49] whitespace-nowrap">
                  Get the opportunity to enhance your portfolio and bring <br />{' '}
                  your creative visions to life.
                </p>
              </div>
              <div className="flex flex-col lg:flex-row space-x-[24px]">
                <Link
                  to="/join"
                  className="btn-green new-btn text-white shadow-lg hover:shadow-xl"
                >
                  Join the waitlist
                </Link>
              </div>
            </div>
            <Suspense
              fallback={
                <div className="mt-[106.8px] w-[100%] lg:w-[40%] lg:mt-[100px] bg-gray-400"></div>
              }
            >
              <div className="mt-[106.8px] lg:w-[40%] lg:mt-[100px]">
                <Image src={hero} alt="hero-pic" classes="w-[100%] h-[100%]" />
              </div>
            </Suspense>
          </div>

          {/* EMPOWER */}

          <div className="w-full flex justify-center items-center flex-col pb-[40px]">
            <h3 className="lg:hidden text-[#0F2936] md:text-center text-[24px] md:text-[36px] pt-[80px] md:pt-[237.5px] font-bold mb-[40px] place-self-start md:place-self-center">
              Empower Your Future: Discover the Benefits of Collaborating with
              Top Brands
            </h3>
            <h3 className="hidden lg:block text-[#0F2936] sm:text-center text-[24px] sm:text-[36px] pt-[80px] sm:pt-[237.5px] font-bold mb-[40px]">
              Empower Your Future: Discover the <br /> Benefits of Collaborating
              with Top Brands
            </h3>
            <div className="grid  sm:grid-cols-2 gap-[24px] pro-text">
              <div className="empower-icons box">
                <div className="py-[20px] sm:py-[32px] px-[24px] text-[20px] font-medium lg:w-[277px]">
                  <img src={connections} alt="icon" />
                  <p>
                    Collaborate with colleagues and industries on research
                    projects.
                  </p>
                </div>
              </div>
              <div className="empower-icons box">
                <div className="py-[20px] sm:py-[32px] px-[24px] text-[20px] font-medium">
                  <img src={insights} alt="icon" className="" />
                  <p>
                    Access industry insights and data to inform career
                    decisions.
                  </p>
                </div>
              </div>
              <div className="empower-icons box">
                <div className="py-[20px] sm:py-[32px] px-[24px] text-[20px] font-medium">
                  <img src={money} alt="icon" />
                  <p>
                    Earn money from brand collaborations directly to your wallet
                  </p>
                </div>
              </div>
              <div className="empower-icons box">
                <div className="py-[20px] sm:py-[32px] px-[24px] text-[20px] font-medium">
                  <img src={talent} alt="icon" />
                  <p>
                    Let your unique talent shine through, become a voice on
                    campus
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CREATORS */}

      <div className="lg:max-w-[80%] mx-auto flex justify-center items-center flex-col mt-[128px]">
        <h3 className="max-w-[710px] text-[#0F2936] md:text-center text-[24px] px-[24px] lg:px-0 lg:text-[36px] font-bold pro justify-self-start">
          Hear from our Successful Creators: How collaboration with top brands
          enhanced their careers
        </h3>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-[32px] pt-[88px]">
          <Card
            className="empower-icons"
            name="John jones"
            position="Buildfoundation"
            person={person3}
          />
          <Card
            className=""
            name="James Kelechi"
            position="Buildfoundation"
            person={person2}
          />
          <Card name="Reward" position="Buildfoundation" person={person1} />
        </div>
      </div>

      {/* ADVERTISE */}
      <div className="lg:max-w-[80%] mx-auto flex justify-center relative mt-5 lg:mt-[100px] px-[24px] h-[380px] lg:h-[350px] mb-[78.5px] ">
        <img src={grid} alt="frame" className="h-[100%]" />
        <div className="absolute flex items-center justify-center">
          <div className="w-full flex flex-col items-center px-4">
            <p className="hidden md:block text-[23px] lg:text-[36px] font-[590] pt-[63px] text-center mb-1 pro-text">
              Share your content to the
            </p>
            <p className="md:hidden text-[23px] lg:text-[36px]  pt-[63px] text-center mb-1 pro-text font-[590]">
              Share your <br /> content to the
            </p>
            <h3 className="md:hidden text-[36px] sm:text-[64px] font-[700] lg:font-[590]  text-center gradient-text">
              Audience that <br /> relates most
            </h3>
            <h3 className="hidden md:block text-[36px] sm:text-[64px] font-[700] lg:font-[590]  text-center gradient-text">
              Audience that relates most
            </h3>
            <Link
              to="/join"
              className="mt-[32px] cta-btn text-white text-[16px] mb-4 shadow-lg hover:shadow-xl"
            >
              Join the waitlist
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Creators;
